import { Box, Flex, ModalCloseButton, ModalHeader } from '@chakra-ui/react';

type Props = {
  title: string;
};
function ModalHeaderComponent({ title }: Props) {
  return (
    <>
      <ModalHeader>
        <Flex justifyContent="space-between">
          <Box w="300px">{title} </Box>
          <Flex
            mr="50px"
            fontFamily="var(--chakra-fonts-body)"
            color="var(--chakra-colors-gray-800)"
            fontSize="var(--chakra-fontSizes-l)"
            fontWeight="var(--chakra-fontWeights-normal)"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          ></Flex>
        </Flex>
      </ModalHeader>
      <ModalCloseButton />
    </>
  );
}

export default ModalHeaderComponent;
