import { CognitoUser } from 'amazon-cognito-identity-js';
import { createContext, useCallback, useState } from 'react';

type SigninInfo = {
  user?: CognitoUser;
  session: string;
  email: string;
  password: string;
  newPassword: string;
  verifyCode: string;
};
type SigninContextInterface = SigninInfo & {
  setSignIn: (signin: Partial<SigninInfo>) => void;
  // setSession: (session?: string) => void;
};

export const defaultContext: SigninContextInterface = {
  user: undefined,
  email: '',
  newPassword: '',
  password: '',
  session: '',
  verifyCode: '',
  setSignIn: (signin: Partial<SigninInfo>) => {},
  // setSession: () => {},
};

export const SigninContext = createContext<SigninContextInterface>(defaultContext);

export const useSignIn = (): SigninContextInterface => {
  const [signInInfo, setSignInInfo] = useState<SigninInfo>({
    email: '',
    newPassword: '',
    session: '',
    password: '',
    verifyCode: '',
    user: undefined,
  });
  const setSignIn = useCallback((signin: Partial<SigninInfo>) => {
    setSignInInfo({
      ...signInInfo,
      session: signin?.session ? signin.session : signInInfo.session,
      email: signin?.email ? signin.email : signInInfo.email,
      user: signin?.user ? signin.user : signInInfo.user,
      password: signin?.password ? signin.password : signInInfo.password,
      newPassword: signin?.newPassword ? signin.newPassword : signInInfo.newPassword,
      verifyCode: signin?.verifyCode ? signin.verifyCode : signInInfo.verifyCode,
    });
  }, []);
  return {
    ...signInInfo,
    setSignIn,
  };
};
