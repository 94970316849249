import { ChallengeNameType } from '@aws-sdk/client-cognito-identity-provider';
import {
  Box,
  Button,
  Center,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { SignInProps } from '../..';
import { getCognitoExceptionMessage } from '../../../../constants/message';
import useAppToast from '../../../../hooks/useAppToast/useAppToast';
import { SigninContext } from '../../hooks/useSignIn';
import '../../index.scss';
import UnverifiedEmailModal from '../UnverifiedEmailModal/UnverifiedEmailModal';

const validationSchema = Yup.object({
  email: Yup.string()
    .email('正しいメールアドレスを入力してください')
    .required('メールアドレスは必須です'),
  password: Yup.string().required('パスワードは必須です'),
});
export const signInQueryParams = {
  enabledUnverificationEmailModal: "unverification-email"
}
const SignIn: React.FC<SignInProps> = ({ setScreenMode }) => {
  const signinContext = useContext(SigninContext);
  const [confirming, setConfirming] = useState<boolean>(false);
  const { displayToast } = useAppToast();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const paramEnabledUnverificationEmailModal = query.get(signInQueryParams.enabledUnverificationEmailModal) ?? '';
  const [enabledUnverificationEmailModal, setEnabledUnverificationEmailModal] = useState(paramEnabledUnverificationEmailModal && JSON.parse(paramEnabledUnverificationEmailModal))
  const handleSubmit = async (values: any) => {
    try {
      const resultSignin = await Auth.signIn({ username: values.email, password: values.password });
      signinContext.setSignIn({
        user: resultSignin,
        email: values.email,
        password: values.password,
        session: resultSignin?.Session,
      });
      if ((resultSignin?.challengeName as ChallengeNameType) === 'NEW_PASSWORD_REQUIRED') {
        setScreenMode('new_password');
      }

      // Eメールが検証済みかどうか確認
      else if (!resultSignin?.attributes?.email_verified) {
      

        await Auth.verifyUserAttribute(resultSignin, 'email');
        setScreenMode('verification_code');
      } else {
        await Auth.signIn(values.email, values.password)
        window.location.replace('/top');
      }
    } catch (error) {
      const message = getCognitoExceptionMessage(error);
      setConfirming(false);
      displayToast('サインイン', message, 'error');
    }
  };
  const handleClick = () => {
    setScreenMode('reset_pass');
  };

  return (
    <>
      <UnverifiedEmailModal isOpen={enabledUnverificationEmailModal}
        onClose={() => setEnabledUnverificationEmailModal(false)}
        setScreenMode={setScreenMode}
      />
    <Center minH="90vh" p={4}>
      <Box
        maxW={{ base: '90%', md: '500px' }}
        w="full"
        p={12}
        className="signin-box"
        borderWidth={1}
        borderRadius="6px"
        boxShadow="1px 1px 0 rgba(0, 0, 0, 0.15)"
        bg="white"
      >
        <Heading as="h3" size="md" textAlign="left" mb={8} mt={4}>
          ログイン
        </Heading>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={(values: any) => {
            setTimeout(() => {
              setConfirming(true);
              handleSubmit(values);
            }, 0);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit}>
              <VStack spacing={5} align="stretch">
                <FormLabel>メールアドレス </FormLabel>
                <Input
                  className="custom-input-signin"
                  type="email"
                  size="lg"
                  name="email"
                  borderRadius={'2px'}
                  value={values.email}
                  placeholder="メールアドレスを入力してください"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
                <FormLabel>パスワード </FormLabel>
                <Input
                  className="custom-input-signin"
                  type="password"
                  size="lg"
                  name="password"
                  borderRadius={'2px'}
                  value={values.password}
                  placeholder="パスワードを入力してください"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </VStack>
              <Box alignSelf="start" mt={3}>
                <Text fontSize="sm" className={'forgotPass'}>
                  パスワードをお忘れの場合?{' '}
                  <Box as="span" className="span-link" onClick={handleClick}>
                    パスワードを変更する
                  </Box>
                </Text>
              </Box>
              <Button
                type="submit"
                className={'custom-signin-button'}
                background={'#ff9900'}
                pointerEvents={confirming || !isValid ? 'none' : 'auto'}
                color="#fff"
                disabled={confirming || !isValid}
                width="full"
                mt={10}
                size="lg"
              >
                {confirming ? <Spinner color="#fff" speed="1s" borderWidth="5px" /> : 'ログイン'}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Center>
    </>
  );
};

export default SignIn;
