import {
  CodeMismatchException,
  ExpiredCodeException,
  InvalidSmsRoleAccessPolicyException,
  LimitExceededException,
  NotAuthorizedException,
} from '@aws-sdk/client-cognito-identity-provider';

export const LOGGER_MESSAGES = {
  PV: 'ページ表示',
  CLICK_ENTER_ROOM: '入室ボタン押下',
  CHECK_LESSON_INFO: 'レッスン情報確認',
} as const;

// cognito
export const COGNITO_ERROR_MESSAGE = {
  VERIFY_CODE_ERROR: '入力された検証コードが間違っています。',
  EXPIRED_CODE: '検証コードの期限が切れています。',
  unknown: '不明なエラーが発生しました。',
} as const;
export type COGNITO_ERROR_MESSAGE_KEY = keyof typeof COGNITO_ERROR_MESSAGE;

export const getCognitoExceptionMessage = (errorInstance: any) => {
  const code = errorInstance?.code;
  if (errorInstance instanceof LimitExceededException || code === 'LimitExceededException') {
    return '検証コードの送信回数の上限に達しました。\n時間をおいてから再度お試しください。';
  } else if (errorInstance instanceof CodeMismatchException || code === 'CodeMismatchException') {
    return COGNITO_ERROR_MESSAGE.VERIFY_CODE_ERROR;
  } else if (
    errorInstance instanceof InvalidSmsRoleAccessPolicyException ||
    code === 'InvalidSmsRoleAccessPolicyException'
  ) {
    return '検証コードの送信に失敗しました。';
  } else if (errorInstance instanceof ExpiredCodeException || code === 'ExpiredCodeException') {
    return '検証コードの期限が切れています。';
  } else if (errorInstance instanceof NotAuthorizedException || code === 'NotAuthorizedException') {
    if (errorInstance.message === 'Incorrect username or password.') {
      return 'メールアドレスまたはパスワードが間違っています。';
    } else if (errorInstance.message === 'Invalid session for the user.') {
      return 'セッションがの有効期限が切れています。\nもう一度最初からやりなおしてください。';
    }
  } else if ((errorInstance as Error)?.message === 'Incorrect username or password.') {
    return 'メールアドレスまたはパスワードが間違っています。';
  }
  return COGNITO_ERROR_MESSAGE.unknown;
};
