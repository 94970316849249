import {
  AlertStatus,
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  FormControl as FormC,
} from '@chakra-ui/react';
import { FormikHelpers } from 'formik';
import { ScreenMode } from '../..';
import ModalHeaderComponent from '../../../../components/ModalHeader';
import { CheckIcon } from '@chakra-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
type Props = {
  isOpen: boolean;
  onClose: () => void;
  setScreenMode: React.Dispatch<React.SetStateAction<ScreenMode>>;
};
/**
 * Eメール未検証モーダル
 * @param param0
 * @returns
 */
const UnverifiedEmailModal: React.FC<Props> = ({ isOpen ,onClose, setScreenMode}) => {
  const { } =  useLocation()
  const history = useHistory()
  
  const handleClose = () => {
    onClose();
    setScreenMode('');
    history.replace("/signin")
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeaderComponent title="" />
        <ModalBody pb={6}>
            <Text>メールアドレスが未検証です。</Text>
            <Text>再度ログインし、メールアドレスの検証を完了してください。</Text>
        </ModalBody>
        <ModalFooter>
            <Button onClick={handleClose} colorScheme="blue" mr={3} leftIcon={<CheckIcon />}>
              OK
            </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default UnverifiedEmailModal;
