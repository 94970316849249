import { I18n } from '@aws-amplify/core';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Container } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { Auth } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { routerActions } from 'connected-react-router';
import React, { lazy, useEffect, useLayoutEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import './App.scss';
import { useAppDispatch, useAppSelector } from './hooks';
import { COGNITO_TRANSLATIONS } from './i18';
import { Shell } from './pages/shell';
import { isLoading, stopLoading } from './slice/loadingSlice';
import { CognitoPayload, signIn, signOutAsync, UserState } from './slice/userSlice';
import { format } from 'date-fns';
import useEventLogger from './hooks/useEventLogger/useEventLogger';
import { useHistory, useLocation } from 'react-router-dom';
import { LOGGER_MESSAGES } from './constants/message';
import { signInQueryParams } from './pages/signin/Components/SignIn/signin';
import { requestServerApi, serverApi } from './api/api';
const Top = lazy(() => import('./pages/top/index'));
const Contact = lazy(() => import('./pages/contact/index'));
const Learner = lazy(() => import('./pages/learner/index'));
const Lessonframe = lazy(() => import('./pages/lessonframe/index'));
const Lessonframes = lazy(() => import('./pages/lessonframes/index'));
const Lesson = lazy(() => import('./pages/lesson/index'));
const Lessons = lazy(() => import('./pages/lessons/index'));
const Profile = lazy(() => import('./pages/profile/index'));
const Notice = lazy(() => import('./pages/notice/index'));
const Signin = lazy(() => import('./pages/signin/index'));
// export let orgKey = React.createContext('');
// export let currentUserId = React.createContext('');
I18n.putVocabulariesForLanguage('ja', COGNITO_TRANSLATIONS);

const PrivateRoute = ({ render, userState, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={
        userState.authState === AuthState.SignedIn &&
        userState.user &&
        userState?.user?.emailVerified
          ? render
          : () => <Redirect to="/signin" />
      }
    />
  );
};
export const Loading = () => (
  <Container className="App-Loading">
    <Spinner />
  </Container>
);

export const extractCognitoPayload = (authenticatedUser: any): CognitoPayload => {
  try {
    return {
      'cognito:username': authenticatedUser.signInUserSession.idToken.payload['cognito:username'],
      'cognito:organizationKey': authenticatedUser.attributes['custom:organizationKey'],
      email: authenticatedUser.signInUserSession.idToken.payload.email,
      exp: authenticatedUser.signInUserSession.idToken.payload.exp,
      emailVerified: !!authenticatedUser?.signInUserSession?.idToken.payload?.email_verified,
      verifiedEmail: authenticatedUser?.attributes['custom:verified_email'],
    };
  } catch (error) {
    throw error;
  }
};

const App = () => {
  const userState = useAppSelector((state) => state.user);
  const isloading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();
  const { sendInfoLog } = useEventLogger();
  const { listen } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    listen((event) => {
      sendInfoLog({
        message: LOGGER_MESSAGES.PV,
        payload: {
          event,
        },
      });
    });
    return () => {
      listen(() => {});
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      const signoutRedirect = async (queryParams: string = '') => {
        await dispatch(signOutAsync());
        dispatch(stopLoading());
        dispatch(routerActions.push('/signin?' + queryParams));
      };

      const checkAuth = async () => {
        const authenticatedUser = await Auth.currentAuthenticatedUser().catch((e) => null);
        if (authenticatedUser) {
          // if (!pathname.includes('/signin')) {
          //   const attributes = await Auth.userAttributes(authenticatedUser);
          //   const verified = attributes.find((e) => e.Name === 'email_verified');
          //   if (!verified || !JSON.parse(verified?.Value)) {
          //     dispatch(enableUnverificationEmailModal({}));
          //     dispatch(stopLoading());
          //     window.location.replace('/signin');
          //     return;
          //   }
          // }
          const expayload = extractCognitoPayload(authenticatedUser);
          if (expayload.emailVerified) {
            dispatch(
              signIn({
                authState: AuthState.SignedIn,
                user: expayload,
              }),
            );
            dispatch(stopLoading());
          } else {
            await signoutRedirect(`${signInQueryParams.enabledUnverificationEmailModal}=true`);
          }

          // orgKey = React.createContext(authenticatedUser.attributes['custom:organizationKey']);
          // currentUserId = React.createContext(authenticatedUser.attributes['sub']);
        } else {
          await signoutRedirect();
        }
      };
      await checkAuth();
    };
    init();
    return onAuthUIStateChange((nextAuthState, authenticatedUser) => {
      if (nextAuthState === AuthState.SignedIn && authenticatedUser) {
        dispatch(
          signIn({
            authState: AuthState.SignedIn,
            user: extractCognitoPayload(authenticatedUser),
          }),
        );
      }
    });
  }, [dispatch]);
  return isloading ? (
    <Loading />
  ) : (
    <Switch>
      <React.Suspense fallback={<Loading />}>
        <Shell>
          <Route
            exact
            path="/"
            render={() => {
              return userState.authState === AuthState.SignedIn &&
                userState.user &&
                userState.user.emailVerified ? (
                <Redirect to="/top" />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/signin"
            render={() => {
              return userState.authState === AuthState.SignedIn &&
                userState.user &&
                userState.user.emailVerified ? (
                <Redirect to="/top" />
              ) : (
                <Signin />
              );
            }}
          />
          <PrivateRoute exact path="/top" render={() => <Top />} userState={userState} />
          <PrivateRoute
            exact
            path="/notices/:noticeId"
            render={() => <Notice />}
            userState={userState}
          />
          <PrivateRoute
            exact
            path="/lessonframes"
            render={() => <Lessonframes />}
            userState={userState}
          />
          <PrivateRoute
            exact
            path="/lessonframes/:lessonFrameId"
            render={() => <Lessonframe />}
            userState={userState}
          />
          <PrivateRoute exact path="/lessons" render={() => <Lessons />} userState={userState} />
          <PrivateRoute
            exact
            path="/lesson/:lessonId"
            render={() => <Lesson />}
            userState={userState}
          />
          <PrivateRoute
            exact
            path="/learner/:learnerId"
            render={() => <Learner />}
            userState={userState}
          />
          <PrivateRoute exact path="/profile" render={() => <Profile />} userState={userState} />
          <PrivateRoute exact path="/contact" render={() => <Contact />} userState={userState} />
        </Shell>
      </React.Suspense>
    </Switch>
  );
};
export default App;

export const reloadIfTokenExpired = (e: any) => {
  const rtn: AxiosResponse = {
    data: [] as never,
    status: 0,
    statusText: '',
    headers: '' as never,
    config: '' as never,
  };
  if (e.message.match(/401/) || e.message.match(/404/)) {
    window.location.reload();
    return rtn;
  }
  return rtn;
};

export const exchangeTimeZone = (datedime: Date) => {
  // console.log(datedime);
  // ローカル時間に変更
  let localDatetime = datedime.toLocaleString();
  // 出力用にフォーマット
  let fromattedTime = format(Date.parse(localDatetime), 'yyyy/MM/dd HH:mm:ss');
  // console.log(fromattedTime);
  return fromattedTime;
};
